import { useCallback, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useHistory } from 'react-router'
import { useQueryParams } from '../common/transition'
import { reset } from '../common/store/slices/application'
import { selectIsLoggedIn, setIsOTPAuthenticated } from '../common/store/slices/authority'
import { rootUrl, accountCreateFormUrl } from '../common/constant/appUrl'
import { 
  saveSsoLinkRedirectTo,
  saveSsoLoginRedirectTo,
  loadDeleteSsoLinkRedirectTo,
  loadDeleteSsoLoginRedirectTo,
} from '../common/sessionStore'
import { federatedSignIn, signOut, getAuthenticatedUser } from '../../utils/authUtil'
import { authIdProviderName } from '../common/constant/processEnv'
import { selectSystemControl } from '../common/store/slices/systemControl'
import { yesNo } from '../common/constant/classification'
import { executeGetAccountIsSsoFirstLogin } from '../../dataAccess/webApi/dao/accountDao'
import { executeGetSessionSettingMst } from '../../dataAccess/webApi/dao/sessionSettingMstDao'
import { executeGetAccountOtp, executePutAccountAuthentication, executePutAccountOtp } from '../../dataAccess/webApi/dao/accountDao'
import { translate } from '../../i18n'

interface UrlParams {
  /** SAML連携エラー時にリダイレクトされたときに設定されているパラメータ */
  error_description?: string
  /** SAML連携エラー時にリダイレクトされたときに設定されているパラメータ */
  error?: string

  /**
   * ログイン後遷移するパス
   */
  loginRedirectTo?: string
  /**
   * SSO連携後に遷移するパス（既にログイン中であることが前提）
   */
  linkRedirectTo?: string
  /**
   * ※この画面に遷移してきたときは、loginRedirectToとlinkRedirectToのどちらかが設定されていることが前提
   * ※未ログイン時に応援サイトのアカウントでログインしたい（SSO未連携の場合は初回連携も実施）場合はloginRedirectToが設定されている
   * ※既にログイン済みでSSOの初回連携を実施したい場合はlinkRedirectToが設定されている
   * 遷移先の値の保持について
   * 　①遷移元画面からloginRedirectToとlinkRedirectToのどちらかが設定された状態でこの画面に遷移してくる
   * 　②フェデレーションサインイン実行しIDP側へリダイレクトしてしまう前に遷移先情報を一旦sessionStoreへ値を退避する
   * 　③IDP側でログイン状態になると、再度この画面にリダイレクトしてくるのでsessionStoreから退避していた遷移先の情報を取得・削除する
   */
}

interface DialogParam {
  isOpen: boolean
  buttonLabel: string,
  onClose: () => void,
  message?: string,
  isButtonOutlined: boolean,
}

const DIALOG_PARAM_DEFAULT_VALUE: DialogParam = {
  isOpen: false,
  buttonLabel: translate('system.button.close'),
  onClose: () => {},
  isButtonOutlined: true,
}

interface SsoLinkedParams {
  id: string
  name: string
  street: string
  houseNumber: string
  city: string
  state: string
  postalCode: string
  email: string
  phone: string
}

export const useAction = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const isLoggedIn = useSelector(selectIsLoggedIn)

  const { ssoUseFlag, ssoUseName, serviceTitle } = useSelector(selectSystemControl)
  
  const queryParams = useQueryParams<UrlParams>()
  
  const [dialog, setDialog] = useState<DialogParam>(DIALOG_PARAM_DEFAULT_VALUE)

  useEffect(() => {
    initialize()
  }, [])
  
  const initialize = useCallback( async () => {
    // 遷移パラメータから取得
    const { loginRedirectTo, linkRedirectTo } = queryParams
    // sessionStoreに保存していた遷移先情報を取得&sessionStoreから遷移先情報を削除
    const loadedLoginTo = loadDeleteSsoLoginRedirectTo()
    const loadedLinkTo = loadDeleteSsoLinkRedirectTo()

    // フェデレーションサインイン実行前、もしくはフェデレーションサインイン成功の場合
    if (!queryParams.error) {
      
      // 未ログイン状態、もしくはログイン済でもSSO連携する場合は、フェデレーションサインインを実行
      if (loginRedirectTo || linkRedirectTo ) {

        if (ssoUseFlag === yesNo.yes && authIdProviderName) {

          // sessionStoreに遷移先を保存
          if (loginRedirectTo) {
            saveSsoLoginRedirectTo(loginRedirectTo)
          } else if(linkRedirectTo) {
            saveSsoLinkRedirectTo(linkRedirectTo)
          }
          // フェデレーションサインイン実行
          federatedSignIn(authIdProviderName)
        }
          
      // フェデレーションサインイン成功した後の場合
      } else {
        // 遷移先　
        // const redirectTo = loadedLoginTo ?? loadedLinkTo ?? rootUrl.url()
        const { isSsoFirstLogin, loginUser } = await getIsSsoFirstLogin()
        
        // ログイン中のSSO連携ではない場合、かつ初回連携ではない場合はそのまま遷移
        // if (!loadedLinkTo) {
        //   if (!isSsoFirstLogin) {
        //     history.replace(redirectTo)
        //     return
        //   }
        // }

        // ログイン中のSSO連携の場合、または初回連携の場合はポップアップに完了メッセージを表示
        if (isSsoFirstLogin) {
          setDialog({
            isOpen: true,
            message: translate('sso.success.completionMessage', ssoUseName ?? '', loginUser.email ?? ''),
            onClose: () => {},
            buttonLabel: translate('system.button.close'),
            isButtonOutlined: true,
          })
        }

        const sessionSetting = await executeGetSessionSettingMst()
        const user = await getAuthenticatedUser(true)
        const userId = user.attributes.sub

        switch (sessionSetting.result.mfaType) {
          case '0':
            await executePutAccountAuthentication({ userId, loginFlag: '1' })
            history.push('/')
            dispatch(setIsOTPAuthenticated(true))
            break
          case '1':
            await executePutAccountOtp({ userId })
            history.push('/otp')
            break
          case '2':
            const response = await executeGetAccountOtp()
            const userInfo = response.result
            if (userInfo.lastOtpLoginDatetime && sessionSetting.result.mfaPeriod) {
              if (idDatePassed(userInfo.lastOtpLoginDatetime, sessionSetting.result.mfaPeriod?.toString())) {
                await executePutAccountOtp({ userId })
                history.push('/otp')
              } else {
                dispatch(setIsOTPAuthenticated(true))
                history.push('/')
              }
            }
            break
        }
      }
      
    // フェデレーションサインインを実行してエラーになった場合
    } else {

      // SSO連携時にloginRedirectToがパラメータに設定されている場合は、SSO失敗時にログアウトする
      if (loadedLoginTo && isLoggedIn) {
        await logout()
      }
    
      const errorDescription = queryParams.error_description
      // アカウント作成画面に渡すユーザの連携情報
      let ssoLinkedParams: SsoLinkedParams
      // エラーダイアログに表示するメッセージ
      let message = translate('system.error.unexpectedError')
      // エラーダイアログに表示するボタンの文言
      let buttonLabel = translate('system.button.close')
      // エラーダイアログに表示するボタンの見た目
      let isButtonOutlined = true
      
      // 遷移先　
      let redirectTo = loadedLoginTo ?? loadedLinkTo ?? rootUrl.url()
      
      if (errorDescription != null && errorDescription.includes('USER_NOT_FOUND:')) {
        
        const itemsJson = unescape(errorDescription).split('USER_NOT_FOUND:')[1].slice(0, -2)
        ssoLinkedParams = JSON.parse(itemsJson)
        
        // SSO連携情報をアカウント作成画面に受け渡す
        if (loadedLoginTo) {
          message = translate('sso.error.userNotFoundMessage', ssoUseName ?? '', ssoLinkedParams.email, serviceTitle ?? '')
          buttonLabel = translate('sso.button.accountCreate')
          isButtonOutlined = false
          redirectTo = accountCreateFormUrl.url()
        } else {
          message = translate('sso.error.errorMessage', ssoUseName ?? '', ssoLinkedParams.email ?? '')
        }
      }
      
      setDialog({
        isOpen: true,
        message: message,
        onClose: () => {onCloseErrorDialog(redirectTo, {ssoLinkedParams: ssoLinkedParams})},
        buttonLabel: buttonLabel,
        isButtonOutlined: isButtonOutlined,
      })
    }
    
  }, [queryParams, ssoUseFlag, ssoUseName, serviceTitle, isLoggedIn])
  
  const onCloseErrorDialog = useCallback((redirectTo: string, ssoLinkedParams?: {ssoLinkedParams: SsoLinkedParams}) => {
    setDialog(DIALOG_PARAM_DEFAULT_VALUE)
    history.replace(redirectTo,　ssoLinkedParams)
    
  }, [queryParams])
  
  const getIsSsoFirstLogin = useCallback( async () => {
    const res = await executeGetAccountIsSsoFirstLogin()
    return res.result
  }, [])

  const logout = useCallback( async () => {
    await signOut()
    dispatch(reset({ isKeepAuthority: false }))
  }, [dispatch])

  const idDatePassed = (
    lastOtpLoginDatetimeString: string,
    mfaPeriodString: string
  ) => {
    const lastOtpLoginDatetime = new Date(lastOtpLoginDatetimeString)

    const today = new Date()

    const mfaPeriod = parseInt(mfaPeriodString, 10)

    const pastDate = new Date(today.getTime() - mfaPeriod * 24 * 60 * 60 * 1000)

    return lastOtpLoginDatetime <= pastDate
  }

  return {
    dialog
  }
}

