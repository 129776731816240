export interface GetAccountDto {
  email: string
  tel: string
  ssoUserId: string | null
}

export interface GetAccountIsSsoFirstLoginDto {
  isSsoFirstLogin: boolean
  loginUser: {
    email: string
  }
}

export interface GetAccountOtpDto {
  userId: string | null
  emailVerifiedFlag: string | null
  emailVerifiedDatetime: string | null
  telVerifiedFlag: string | null
  telVerifiedDatetime: string | null
  otp: string | null
  generateDatetime: string | null
  lastOtpLoginDatetime: string | null
  loginFlag: string | null
  updateDatetime : string | null
}

export interface PutAccountInputDto {
  accessToken: string
  email: string | null
  tel: string | null
}

export interface PostAccountInputDto {
  accessToken: string
  category: string
  passcode: string
}

/** PostAccountIdentityVerifySubmitInputDtoのcategory */
export const accountIdentityVerifySubmitCategory = {
  email: '1',
  tel: '2',
} as const

export interface PostAccountIdentityVerifySubmitInputDto {
  accessToken: string
  category: string
  passcode: string
}

export interface PutAccountIdentityVerifyResendEmailInputDto {
  accessToken: string
  email: string
}
