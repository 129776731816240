import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { RootState } from '..'
import { ElapsedMillisecond } from '../../../../utils/dateUtil'
interface MapPosition {
  latitude: number
  longitude: number
}

export interface SearchCondition {
  /** 現在地 */
  currentLocation?: MapPosition
  /** 地域 */
  areas?: string[]
  /** 年齢 */
  ages?: string[]
  /** フリーワード */
  freeWord?: string
  /** Web受付可否 */
  reservationAcceptFlag?: string[]
  /** 事業ID */
  projectIds?: string[]
  /** 事業項目が選択状態 */
  isProjectSelected?: boolean
  /** 施設種別 */
  facilityCategories?: string[]
  /** その他詳細条件 */
  otherDetailConditions?: string[]
  /** 利用日 */
  usageDate?: ElapsedMillisecond
  /** 利用目的 */
  purposeOfUses?: string[]
  /** 並び順 */
  sortOrder?: string
}
export interface Facility {
  facilityId: string
  facilityName: string
  projectName?: string
  address1?: string
  address2?: string
  buildingNameRoomNo?: string
  openTimeWeekday?: string
  openTimeSaturday?: string
  openTimeHoliday?: string
  latitude: number
  longitude: number
  nearestStation?: string
  imageUrl?: string
  acceptStartAge?: number
  acceptStartMonth?: number
  acceptStartDay?: number
  acceptStartAgeGradeFlag?: string
  acceptEndAge?: number
  acceptEndMonth?: number
  acceptEndDay?: number
  acceptEndAgeGradeFlag?: string
  reservationAcceptFlag: string
}

interface SearchResult {
  isDirty: boolean
  isLimitOver: boolean
  allFacilities: Facility[]
  mapCenter?: MapPosition
  activeFacilityId?: string
}

export interface CodeEntry {
  value: string
  label: string
}
export interface CodeMaster {
  /** 地域 */
  areas?: CodeEntry[]
  /** 年齢 */
  ages?: CodeEntry[]
  /** Web予約可否 */
  reservationAcceptFlag?: CodeEntry[]
  /** 事業 */
  projects?: CodeEntry[]
  /** 事業項目が選択状態 */
  isProjectSelected?: boolean
  /** 施設種別 */
  facilityCategories?: CodeEntry[]
  /** その他詳細条件 */
  otherDetailConditions?: CodeEntry[]
  /** 利用目的 */
  purposeOfUses?: CodeEntry[]
}

interface FacilitySearchState {
  condition?: SearchCondition
  result?: SearchResult
  codeMaster: CodeMaster
  isDisabledCurrentLocation: boolean
}

const initialState: FacilitySearchState = {
  codeMaster: {},
  isDisabledCurrentLocation: false,
}

export const facilitySearchSlice = createSlice({
  name: 'facilitySearch',
  initialState,
  reducers: {
    setFacilitySearchCondition: (state, action: PayloadAction<SearchCondition>) => {
      state.condition = action.payload
      if (state.result) {
        state.result.isDirty = true
      }
    },
    clearFacilitySearchCondition: (state) => {
      delete state.condition
    },
    setFacilitySearchSortOrder: (
      state,
      action: PayloadAction<{ sortOrder: string; currentLocation?: MapPosition; isDisabledCurrentLocation?: boolean }>
    ) => {
      if (state.condition) {
        state.condition.sortOrder = action.payload.sortOrder
        if (action.payload.currentLocation) {
          state.condition.currentLocation = action.payload.currentLocation
        }
        if (state.result) {
          state.result.isDirty = true
        }
      }
      if (action.payload.isDisabledCurrentLocation != null) {
        state.isDisabledCurrentLocation = action.payload.isDisabledCurrentLocation
      }
    },
    setFacilitySearchResult: (
      state,
      action: PayloadAction<{ searchResult?: { isLimitOver: boolean; allFacilities: Facility[] } }>
    ) => {
      const { searchResult } = action.payload
      if (searchResult) {
        const { allFacilities, isLimitOver } = searchResult
        state.result = { allFacilities, isLimitOver, isDirty: false }
        const firstFaclitity = allFacilities[0]
        if (firstFaclitity) {
          const { latitude, longitude } = firstFaclitity
          state.result.mapCenter = { latitude, longitude }
        }
      }
    },
    setActiveFacility: (state, action: PayloadAction<string>) => {
      if (state.result) {
        state.result.activeFacilityId = action.payload
      }
    },
    clearActiveFacility: (state) => {
      if (state.result) {
        delete state.result.activeFacilityId
      }
    },
    clearFacilitySearchResult: (state) => {
      delete state.result
    },
    setMapCenter: (state, action: PayloadAction<MapPosition>) => {
      if (state.result != null) {
        state.result.mapCenter = action.payload
      }
    },
    setFacilitySearchCodeMaster: (state, action: PayloadAction<CodeMaster>) => {
      state.codeMaster = { ...state.codeMaster, ...action.payload }
    },
  },
})

export const {
  setFacilitySearchCondition,
  clearFacilitySearchCondition,
  setFacilitySearchSortOrder,
  setFacilitySearchResult,
  setActiveFacility,
  clearActiveFacility,
  clearFacilitySearchResult,
  setMapCenter,
  setFacilitySearchCodeMaster,
} = facilitySearchSlice.actions
export const selectFacilitySearchCondition = (state: RootState) => state.facilitySearch.condition
export const selectResultIsDirty = (state: RootState) => state.facilitySearch.result?.isDirty
export const selectResultIsLimitOver = (state: RootState) => state.facilitySearch.result?.isLimitOver ?? false
export const selectResultAllFacilities = (state: RootState) => state.facilitySearch.result?.allFacilities
export const selectResultActiveFacilityId = (state: RootState) => state.facilitySearch.result?.activeFacilityId
export const selectResultMapCenter = (state: RootState) => state.facilitySearch.result?.mapCenter
export const selectFacilitySearchCodeMaster = (state: RootState) => state.facilitySearch.codeMaster
export const selectIsDisabledCurrentLocation = (state: RootState) => state.facilitySearch.isDisabledCurrentLocation
export default facilitySearchSlice.reducer
