import { useEffect } from 'react'
import { useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePutAccount, resultCodePutAccount } from '../../dataAccess/webApi/dao/accountDao'
import { translate } from '../../i18n'
import { getSessionAccessToken } from '../../utils/authUtil'
import { accountUrl, accountChangeVerificationUrl } from '../common/constant/appUrl'
import { accountChangeVerificationCategory, yesNo } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { useOperationLog } from '../common/operationLog'
import { setAccountChange } from '../common/store/slices/accountChange'
import { notifyMessage, showLoading } from '../common/store/slices/application'
import { detectLoginStatus } from '../common/store/slices/authority'
import { selectSystemControl } from '../common/store/slices/systemControl'

interface Inputs {
  newTel: string
  newTelConfirmation: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const formMethods = useForm<Inputs>()
  const { addOperationLog } = useOperationLog()
  const sysCtrl = useSelector(selectSystemControl)

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001, accessData: [{ userIdRegFlag: yesNo.yes }] })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const onSubmit = (data: Inputs) => {
    addOperationLog({ operationId: OperationId.OP_00000035, accessData: [{ userIdRegFlag: yesNo.yes }] })

    if (data.newTel !== data.newTelConfirmation) {
      formMethods.setError(
        'newTelConfirmation',
        { message: translate('system.error.notSameTel') },
        { shouldFocus: true }
      )
      return
    }
    dispatch(
      showLoading({
        process: errorHandle(async () => {
          const response = await executePutAccount({
            accessToken: await getSessionAccessToken(),
            tel: data.newTel,
            email: null,
          })
          if (response.resultCode) {
            // 登録失敗時
            let newTelErrorKey
            switch (response.resultCode) {
              case resultCodePutAccount.notChangedTel:
                newTelErrorKey = 'accountTelChange.error.notChanged'
                break
              case resultCodePutAccount.alreadyRegisteredTel:
                newTelErrorKey = 'accountTelChange.error.alreadyRegistered'
                break
            }
            if (newTelErrorKey) {
              // ローディング表示中はsetErrorでメッセージ表示が反映されないので遅延させる
              const errorKey = newTelErrorKey
              setTimeout(() => {
                formMethods.setError('newTel', { message: translate(errorKey) }, { shouldFocus: true })
              }, 0)
            }
          } else {
            if (sysCtrl.phoneCertificationFlag === yesNo.yes) {
              // SMS認証が必要な場合
              dispatch(
                setAccountChange({
                  category: accountChangeVerificationCategory.tel,
                  tel: data.newTel,
                })
              )
              dispatch(notifyMessage(translate('system.success.sendOneTimePassword')))
              history.push(accountChangeVerificationUrl.url())
            } else {
              // SMS認証が不要な場合
              await dispatch(detectLoginStatus())
              dispatch(notifyMessage(translate('accountChangeVerification.success.completionMessageTel')))
              history.push(accountUrl.url())
            }
          }
        }),
        isHiddenMain: false,
      })
    )
  }

  return {
    formMethods,
    onSubmit,
    sysCtrl,
  }
}
