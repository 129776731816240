import { useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router-dom'
import { executePostInterview, executePutInterview, executeGetSameAddChildInterviews } from '../../dataAccess/webApi/dao/interviewsDao'
import { GetSameAddChildInterviewsDto } from '../../dataAccess/webApi/dto/interviewsDto'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { toApiYmd, toApiYmdHms } from '../../utils/dateUtil'
import { undefinedPropsToNull } from '../../utils/objectUtil'
import { blankToNull } from '../../utils/stringUtil'
import { castNonNullable, NullPropsToUndefinedType } from '../../utils/typeUtil'
import { getInterviewReservationSteps, getInterviewReservationSteps2 } from '../common/codeMaster'
import { getInterview } from '../common/interview'
import { interviewReservationCompletionUrl } from '../common/constant/appUrl'
import { yesNo, interviewAcceptMethod } from '../common/constant/classification'
import { OperationId } from '../common/constant/operationLog'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getLatestAddOperationDatetime, useOperationLog } from '../common/operationLog'
import { showLoading } from '../common/store/slices/application'
import {
  InterviewReserveEntry,
  //clearInterviewReserve,
  selectInterviewReserveEntry,
  selectInterviewReserveUpdateDatetimes,
} from '../common/store/slices/interviewReserve'

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const history = useHistory()
  const [facility, setFacility] = useState<NullPropsToUndefinedType<GetFacilityDto>>()
  const [facilityName, setFacilityName] = useState<string>()
  const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState<boolean>(false)
  const [sameAddChildInfo, setAddChildInfo] = useState<GetSameAddChildInterviewsDto[]>()
  const [interviewResultCode, setInterviewResultCode] = useState<number>()
  const { addOperationLog } = useOperationLog()

  const reserveEntry = useSelector(selectInterviewReserveEntry)
  const updateDatetimes = useSelector(selectInterviewReserveUpdateDatetimes)

  const steps = useMemo(
    (facility?.interviewDailyAcceptFlag === yesNo.yes && facility?.interviewAcceptMethod === interviewAcceptMethod.interviewSlots)
      ? getInterviewReservationSteps2 : getInterviewReservationSteps,
    [facility]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          if (reserveEntry) {
            const facility = await getFacility(reserveEntry.facilityId)
            setFacility(facility)
            setFacilityName(facility.facilityName)
          }
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // 登録処理
  const decideFinal = useCallback((sameUpdateFlag: boolean) => async () => {
    addOperationLog({ operationId: OperationId.OP_00000026 })
    const operationLogDatetime = castNonNullable(getLatestAddOperationDatetime())

    let success = false
    await dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (reserveEntry && updateDatetimes && !reserveEntry.interviewNo) {
            // 面談登録
            const response = await postInterview(
              reserveEntry,
              updateDatetimes.userUpdateDatetime,
              operationLogDatetime,
              facility?.interviewDailyAcceptFlag ?? yesNo.no,
              facility?.interviewAcceptMethod ?? interviewAcceptMethod.applicationOnly,
            )
            if (response.resultCode) {
              // 登録失敗時
              setInterviewResultCode(response.resultCode)
            } else {
              // 登録成功時
              success = true
              //history.push(interviewReservationCompletionUrl.url())
              //dispatch(clearInterviewReserve())
            }
          } else if (reserveEntry && updateDatetimes && reserveEntry.interviewNo && updateDatetimes.interviewUpdateDatetime) {
            // 面談更新
            const response = await putInterview(
              reserveEntry,
              reserveEntry.interviewNo,
              updateDatetimes.userUpdateDatetime,
              updateDatetimes.interviewUpdateDatetime,
              operationLogDatetime,
              facility?.interviewDailyAcceptFlag ?? yesNo.no,
              sameUpdateFlag,
              sameAddChildInfo ?? [],
              facility?.interviewAcceptMethod ?? interviewAcceptMethod.applicationOnly,
            )
            if (response.resultCode) {
              // 登録失敗時
              setInterviewResultCode(response.resultCode)
            } else {
              // 登録成功時
              success = true
              //history.push(interviewReservationCompletionUrl.url())
              //dispatch(clearInterviewReserve())
            }
          }
        }),
        isHiddenMain: false,
      })
    )

    // エラーが発生していない場合に次画面に遷移
    if (success) {
      history.push(interviewReservationCompletionUrl.url())
    }
  }, [addOperationLog, dispatch, errorHandle, history, reserveEntry, updateDatetimes, sameAddChildInfo, facility])

  // 登録前処理
  const decide = useCallback(async () => {
    let decideFinalFlag = false
    await dispatch(
      showLoading({
        process: errorHandle(async () => {
          if (reserveEntry && updateDatetimes && !reserveEntry.interviewNo) {
            // 面談登録
            // 登録処理を実行する
            decideFinalFlag = true
          } else if (reserveEntry && updateDatetimes && reserveEntry.interviewNo && updateDatetimes.interviewUpdateDatetime) {
            // 面談変更
            // 変更前の面談情報を取得
            const interview = await getInterview(reserveEntry.interviewNo)

            // 変更後の面談開始終了日時を取得
            const newInterviewDate = reserveEntry.interviewDatetimes && toApiYmd(reserveEntry.interviewDatetimes[0].interviewDate)
            const newInterviewFromDatetime = reserveEntry.interviewDatetimes && toApiYmdHms(reserveEntry.interviewDatetimes[0].interviewFromDatetime)
            const newInterviewToDatetime = reserveEntry.interviewDatetimes && toApiYmdHms(reserveEntry.interviewDatetimes[0].interviewToDatetime)

            if (toApiYmd(interview.interviewDate) !== newInterviewDate
                || toApiYmdHms(interview.interviewStartDatetime) !== newInterviewFromDatetime
                || toApiYmdHms(interview.interviewEndDatetime) !== newInterviewToDatetime) {
              // 面談日、面談開始時間、面談終了時間のどれかが変更ある場合に
              // 同時更新できるお子様の面談があるか取得
              const getSameAddChildInterviewsRes = await executeGetSameAddChildInterviews(
                reserveEntry.interviewNo,
              )
              if (getSameAddChildInterviewsRes.resultCode) {
                // 失敗時
                setInterviewResultCode(getSameAddChildInterviewsRes.resultCode)
              } else {
                // 成功時
                // お子さま名を設定
                if (getSameAddChildInterviewsRes.result && getSameAddChildInterviewsRes.result.length > 0) {
                  setAddChildInfo(getSameAddChildInterviewsRes.result)
                  setIsConfirmDialogOpen(true)
                } else {
                  // 登録処理を実行する
                  decideFinalFlag = true
                }
              }
            } else {
              // 登録処理を実行する
              decideFinalFlag = true
            }
          }
        }),
        isHiddenMain: false,
      })
    )

    if (decideFinalFlag) {
      // 登録処理
      dispatch(decideFinal(false))
    }
  }, [addOperationLog, dispatch, errorHandle, decideFinal, history, reserveEntry, updateDatetimes])

  // 同時更新確認ダイアログ：同時更新しない
  const onConfirmDialogNo = useCallback(async() => {
    // ダイアログを閉じて登録処理を実行
    setIsConfirmDialogOpen(false)
    dispatch(decideFinal(false))
  },[dispatch, decideFinal])

  // 同時更新確認ダイアログ：同時更新する
  const onConfirmDialogYes = useCallback(async() => {
    // ダイアログを閉じて登録処理を実行
    setIsConfirmDialogOpen(false)
    dispatch(decideFinal(true))
  },[dispatch, decideFinal])

  // 同時更新確認ダイアログ：閉じる
  const onConfirmDialogClose = useCallback(() => {
    //閉じたくないので処理なし
  },[])

  let applicationChildren: any[] = []
  if (reserveEntry && reserveEntry.children) {
    applicationChildren = reserveEntry.children.filter(child => child.availabilityOfApplication === yesNo.yes)
  }

  return {
    reserveEntry,
    facilityName,
    steps,
    interviewResultCode,
    decide,
    applicationChildren,
    isConfirmDialogOpen,
    onConfirmDialogClose,
    onConfirmDialogNo,
    onConfirmDialogYes,
    sameAddChildInfo,
    facility,
  }
}

/**
 * 面談登録パラメータ設定
 */
const postInterview = (
  entry: InterviewReserveEntry,
  userUpdateDatetime: string,
  operationLogDatetime: string,
  interviewDailyAcceptFlag: string,
  facilityInterviewAcceptMethod: string,
) => {

  let interviewDatetime
  if ((interviewDailyAcceptFlag === yesNo.yes && facilityInterviewAcceptMethod === interviewAcceptMethod.interviewSlots)
        && entry.interviewDatetimes && entry.interviewDatetimes.length > 0) {
      interviewDatetime = {
        interviewDate: toApiYmd(entry.interviewDatetimes[0].interviewDate),
        interviewFromDatetime: toApiYmdHms(entry.interviewDatetimes[0].interviewFromDatetime),
        interviewToDatetime: toApiYmdHms(entry.interviewDatetimes[0].interviewToDatetime),
      }
  }
  let applicationChildren: any[] = []
  if (entry && entry.children) {
    applicationChildren = entry.children.filter(child => child.availabilityOfApplication === yesNo.yes)
  }  
  return executePostInterview({
    ...undefinedPropsToNull(
      {
        facilityId: entry.facilityId,
        parentName: castNonNullable(entry.parentName),
        parentKana: castNonNullable(entry.parentKana),
        postalCode: castNonNullable(entry.postalCode),
        address1: castNonNullable(entry.address1),
        address2: castNonNullable(entry.address2),
        buildingNameRoomNumber: blankToNull(entry.buildingNameRoomNumber),
        residenceCategory: castNonNullable(entry.residenceCategory),
        relationship: blankToNull(entry.relationship),
        userUpdateDatetime,
        operationLogDatetime,
        children:
          !applicationChildren ? [] : applicationChildren.map((child) => {
            return (
              {
                childId: child.childId,
                childName: child.childName,
                childKana: child.childKana,
                childGender: child.childGender,
                childBirthday: toApiYmd(child.childBirthday),
                childAllergyFlag: child.childAllergyFlag,
                childAllergy: blankToNull(child.childAllergy),
                childMedicalHistoryFlag: child.childMedicalHistoryFlag,
                childMedicalHistory: blankToNull(child.childMedicalHistory),
                //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
                //maternalHandbookNo: child.maternalHandbookNo,
                note: blankToNull(child.note),
                facilityNumber: blankToNull(child.facilityNumber),
                childUpdateDatetime: child.childUpdateDatetime,
              }
            )
          }),
      }
    ),
    ...(interviewDatetime && {interviewDatetime}),
  })
}

/**
 * 面談更新パラメータ設定
 */
const putInterview = (
  entry: InterviewReserveEntry,
  interviewNo: string,
  userUpdateDatetime: string,
  interviewUpdateDatetime: string,
  operationLogDatetime: string,
  interviewDailyAcceptFlag: string,
  sameUpdateFlag: boolean,
  sameAddChildInfo: GetSameAddChildInterviewsDto[],
  facilityInterviewAcceptMethod: string,
) => {

  let interviewDatetime
  if ((interviewDailyAcceptFlag === yesNo.yes && facilityInterviewAcceptMethod === interviewAcceptMethod.interviewSlots)
        && entry.interviewDatetimes && entry.interviewDatetimes.length > 0) {
      interviewDatetime = {
        interviewDate: toApiYmd(entry.interviewDatetimes[0].interviewDate),
        interviewFromDatetime: toApiYmdHms(entry.interviewDatetimes[0].interviewFromDatetime),
        interviewToDatetime: toApiYmdHms(entry.interviewDatetimes[0].interviewToDatetime),
      }
  }

  return executePutInterview(
    interviewNo,
    {...undefinedPropsToNull(
      {
        facilityId: entry.facilityId,
        parentName: castNonNullable(entry.parentName),
        parentKana: castNonNullable(entry.parentKana),
        postalCode: castNonNullable(entry.postalCode),
        address1: castNonNullable(entry.address1),
        address2: castNonNullable(entry.address2),
        buildingNameRoomNumber: blankToNull(entry.buildingNameRoomNumber),
        residenceCategory: castNonNullable(entry.residenceCategory),
        relationship: blankToNull(entry.relationship),
        userUpdateDatetime,
        interviewUpdateDatetime,
        operationLogDatetime,
        children:
          !entry.children ? [] : entry.children.map((child) => {
            return (
              {
                childId: child.childId,
                childName: child.childName,
                childKana: child.childKana,
                childGender: child.childGender,
                childBirthday: toApiYmd(child.childBirthday),
                childAllergyFlag: child.childAllergyFlag,
                childAllergy: blankToNull(child.childAllergy),
                childMedicalHistoryFlag: child.childMedicalHistoryFlag,
                childMedicalHistory: blankToNull(child.childMedicalHistory),
                //TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
                //maternalHandbookNo: child.maternalHandbookNo,
                note: blankToNull(child.note),
                facilityNumber: blankToNull(child.facilityNumber),
                childUpdateDatetime: child.childUpdateDatetime,
              }
            )
          }),
        sameAddChildren:
          (!sameUpdateFlag || !sameAddChildInfo.length) ? [] : sameAddChildInfo.map((child) => {
            return (
              {
                interviewsNo: child.interviewNo,
                childId: child.childId,
                interviewUpdateDatetime: child.interviewUpdateDatetime,
              }
            )
          }),
      }
    ),
    ...(interviewDatetime && {interviewDatetime}),
  })
}
