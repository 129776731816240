import { useState, useCallback, useEffect } from 'react'
import { Grid, Stack, Button, styled } from '@mui/material'
import { useSelector } from 'react-redux'
import { selectSystemControl } from '../../containers/common/store/slices/systemControl'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { GContainer, GItem } from '../components/common/grids'
import { DownloadIcon } from '../components/common/icons/downloadIcon'
import { downloadFile } from '../../utils/reactUtil'
import { SubTitle } from '../components/common/subTitle'
import { GetFormatMessage } from '../../containers/common/messageConverter'
import awsmobile from '../../aws-exports'
import {
  ListObjectsCommand,
  ListObjectsCommandInput,
  ListObjectsCommandOutput,
  S3Client,
} from '@aws-sdk/client-s3'
import { Auth } from 'aws-amplify'
const bucketUrl = `https://{0}.s3.{1}.amazonaws.com/{2}/`

export const Manual = () => {
  const [citizenFileName, setCitizenFileName] = useState<string[]>(['', '', '', '', ''])
  console.log(citizenFileName)

  const bucketName = useSelector(selectSystemControl).s3BucketNameManual
  const region = awsmobile.aws_project_region

  const initialize = useCallback(async () => {

    try {

      const s3 = new S3Client({
        region: region,
        credentials: await Auth.currentCredentials(),
      })

      const param: ListObjectsCommandInput = {
        Bucket: bucketName,
        Prefix: 'citizen' + '/',
      }

      // オブジェクトリストを取得
      const listObjectsResults = await s3.send(new ListObjectsCommand(param))

      if (listObjectsResults.Contents !== undefined) {
        const pdfFiles = listObjectsResults.Contents
          .filter((obj) => obj.Key && /\.pdf$/i.test(obj.Key)) // Keyが存在し、.pdfで終わるものをフィルタリング
          .map((obj) => ({
            Key: obj.Key, // Keyを取得
            LastModified: obj.LastModified, // LastModifiedを取得
          }));

        const prefixes = ['01', '02', '03', '04', '05']

        const fileNameList = [];

        // todoリファクタリング 関数化
        for (let i = 0; i < prefixes.length; i++) {
          const regex = new RegExp(`/${prefixes[i]}/`); // 正規表現を動的に生成
          const folderFiles = pdfFiles.filter(file => file.Key && regex.test(file.Key));

          const targetObject = folderFiles.sort((a, b) => {
            // 最終更新日で降順ソート
            const timeA = a.LastModified?.getTime() ?? 0
            const timeB = b.LastModified?.getTime() ?? 0
            return timeB - timeA
          })[0] ?? null

          if (targetObject !== null) {
            const objectKey = targetObject?.Key ?? ''
            // s3のキー名からファイル名を取得
            const targetFileName = objectKey.split('/').pop()
            fileNameList.push(targetFileName)
          }
        }
        // undefinedの可能性があるファイルを除去
        const trimedFileNameList = fileNameList.filter((file): file is string => file !== undefined)

        // useStateに格納
        setCitizenFileName(trimedFileNameList)
      }
    } catch (err) {
      console.log(err)
    }
  }, [])
  useEffect(() => {
    initialize()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  // pdfダウンロード
  const downloadClick = async (folderNum: string) => {
    // 認証情報を使用して S3 クライアントを初期化
    const s3 = new S3Client({
      region: region,
      credentials: await Auth.currentCredentials(),
    })

    // Prefix 配下のオブジェクトをリスト化するためのパラメータ
    const param: ListObjectsCommandInput = {
      Bucket: bucketName,
      Prefix: 'citizen' + '/' + folderNum,
    }

    // オブジェクトリストを取得
    const listObjectsCommandOutput: ListObjectsCommandOutput = await s3.send(new ListObjectsCommand(param))

    // Prefix 直下のファイルのみ抽出
    const targetFiles = listObjectsCommandOutput.Contents?.sort((a, b) => {
      // 最終更新日で降順ソート
      const timeA = a.LastModified?.getTime() ?? 0
      const timeB = b.LastModified?.getTime() ?? 0

      return timeB - timeA
    })
      .map((s3Object) => s3Object.Key)
      .filter((s3ObjectKey) => !s3ObjectKey?.endsWith('/')) // フォルダを除外
      .filter((s3ObjectKey): s3ObjectKey is string => s3ObjectKey !== undefined)
      .map((s3ObjectKey) => s3ObjectKey.split('/')[2]) // ファイル名を取得

    // 最新の更新ファイルを取得
    const targetFileName = targetFiles?.[0] ?? ''

    // S3 オブジェクト URL を生成
    const targetUrl = GetFormatMessage(bucketUrl, [bucketName, region, 'citizen']) + folderNum + '/' + encodeURIComponent(targetFileName)

    // ファイルダウンロード
    downloadFile(targetUrl)
  }

  const PdfConponent = styled('div')(() => ({
    display: 'flex',
    justifyContent: 'space-between',
    gap: '30px',
    width: '100%',
    alignItems: 'center',
    padding: '0px 15px 20px 15px',
  }))

  return (
    <Stack spacing={2}>
      <Grid container>
        <Grid item md={3}></Grid>
        <Grid item xs={12} md={6}>
          <Stack spacing={2}>
            <GContainer>
              <GItem xs={12}>
                <SubTitle title={'操作マニュアル'} />
              </GItem>
            </GContainer>
            <div>
              {/* 
              マニュアルはダウンロードを強制しています。
              download属性は同一オリジンの URL と、 blob:、 data: の各スキームでのみ動作するので、
              download属性は使用せず
              S3側でpdfのコンテンツタイプをapplication/octet-streamに設定してダウンロードを強制させるようにした
              https://developer.mozilla.org/ja/docs/Web/HTML/Element/a
              */}
            </div>
            {/* 操作マニュアル1 */}
            {citizenFileName[0] && (
              <PdfConponent>
                <label>{citizenFileName[0]}</label>
                <Button
                  sx={{ height: '50px', width: '30px' }}
                  variant="contained"
                  onClick={() => downloadClick('01')}
                >
                  <DownloadIcon />
                </Button>
              </PdfConponent>
            )}
            
            {/* 操作マニュアル2 */}
            {citizenFileName[1] && (
              <PdfConponent>
                <label>{citizenFileName[1]}</label>
                <Button
                  sx={{ height: '50px', width: '30px' }}
                  variant="contained"
                  onClick={() => downloadClick('02')}
                >
                  <DownloadIcon />
                </Button>
              </PdfConponent>
            )}

            {/* 操作マニュアル3 */}
            {citizenFileName[2] && (
              <PdfConponent>
                <label>{citizenFileName[2]}</label>
                <Button
                  sx={{ height: '50px', width: '30px' }}
                  variant="contained"
                  onClick={() => downloadClick('03')}
                >
                  <DownloadIcon />
                </Button>
              </PdfConponent>
            )}

            {/* 操作マニュアル4 */}
            {citizenFileName[3] && (
              <PdfConponent>
                <label>{citizenFileName[3]}</label>
                <Button
                  sx={{ height: '50px', width: '30px' }}
                  variant="contained"
                  onClick={() => downloadClick('04')}
                >
                  <DownloadIcon />
                </Button>
              </PdfConponent>
            )}

            {/* 操作マニュアル5 */}
            {citizenFileName[4] && (
              <PdfConponent>
                <label>{citizenFileName[4]}</label>
                <Button
                  sx={{ height: '50px', width: '30px' }}
                  variant="contained"
                  onClick={() => downloadClick('05')}
                >
                  <DownloadIcon />
                </Button>
              </PdfConponent>
            )}
          </Stack>
        </Grid>
        <Grid item md={3}></Grid>
      </Grid>
      <BottomButtonGroup>
        <BackButton />
      </BottomButtonGroup>
    </Stack>
  )
}
