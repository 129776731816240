import { SelectChangeEvent } from '@mui/material'
import { SyntheticEvent, useCallback, useEffect, useMemo, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'
import { executeGetFacilities, resultCodeGetFacilities } from '../../dataAccess/webApi/dao/facilitiesDao'
import { GetFacilitiesInputDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { formatYmd, toApiYmd } from '../../utils/dateUtil'
import { nullPropsToUndefined, undefinedPropsToNull } from '../../utils/objectUtil'
import { blankToNull, toInts } from '../../utils/stringUtil'
import { getFacilitySearchSortOrders } from '../common/codeMaster'
import { facilityDetailUrl } from '../common/constant/appUrl'
import { facilitySearchSortOrder } from '../common/constant/classification'
import { useErrorHandle } from '../common/error/errorHandler'
import { getCurrentPosition } from '../common/location'
import { showLoading } from '../common/store/slices/application'
import {
  CodeEntry,
  CodeMaster,
  SearchCondition,
  selectFacilitySearchCodeMaster,
  selectFacilitySearchCondition,
  selectIsDisabledCurrentLocation,
  selectResultAllFacilities,
  selectResultIsDirty,
  selectResultIsLimitOver,
  selectResultMapCenter,
  setActiveFacility,
  setFacilitySearchCondition,
  setFacilitySearchResult,
  setFacilitySearchSortOrder,
  setMapCenter,
} from '../common/store/slices/facilitySearch'

interface LocationState {
  /** アクティブなタブの値 */
  tabValue?: string
  conditionExpanded?: boolean
}

/** タブの選択値 */
export const tabValue = {
  mapHide: '1',
  mapShow: '2',
} as const

export const useAction = () => {
  const history = useHistory<LocationState | undefined>()
  const nextHistory = useHistory()
  const dispatch = useDispatch()

  const [conditionExpanded, setConditionExpanded] = useState(history.location.state?.conditionExpanded ?? false)
  const [activeTabValue, setActiveTabValue] = useState(history.location.state?.tabValue ?? tabValue.mapHide)

  const searchCondition = useSelector(selectFacilitySearchCondition)
  const isResultDirty = useSelector(selectResultIsDirty)
  const isLimitOver = useSelector(selectResultIsLimitOver)
  const allFacilities = useSelector(selectResultAllFacilities)
  const mapCenter = useSelector(selectResultMapCenter)
  const codeMaster = useSelector(selectFacilitySearchCodeMaster)
  const isDisabledCurrentLocation = useSelector(selectIsDisabledCurrentLocation)

  const conditionTags = useMemo(
    () => (codeMaster && searchCondition ? getConditionTags(codeMaster, searchCondition) : []),
    [codeMaster, searchCondition]
  )
  const sortOrders = useMemo(
    () =>
      getFacilitySearchSortOrders().filter(
        ({ value }) => !isDisabledCurrentLocation || value !== facilitySearchSortOrder.currentLocation
      ),
    [isDisabledCurrentLocation]
  )
  const sortOrder = sortOrders.find(({ value }) => value === searchCondition?.sortOrder)?.value ?? sortOrders[0].value

  const goBack = useCallback(() => {
    if (!searchCondition?.isProjectSelected) {
      dispatch(setFacilitySearchCondition({ ...searchCondition, projectIds: []}))
    }
    history.goBack()
  }, [history, dispatch])

  const changeOrder = useCallback(
    (event: SelectChangeEvent<string>) => {
      dispatch(
        setFacilitySearchSortOrder({
          sortOrder: event.target.value,
        })
      )
    },
    [dispatch]
  )

  const onClickCurrentLocation = useCallback(async () => {
    try {
      const position = await getCurrentPosition()
      dispatch(
        setMapCenter({
          latitude: position.coords.latitude,
          longitude: position.coords.longitude,
        })
      )
    } catch {
      // 何もしない
    }
  }, [dispatch])
  const onClickFacilityPin = useCallback(
    (facilityId: string) => {
      // スマホでは地図と結果が縦に並び、即スクロールさせると施設名が誤タップされる場合がある
      // これを防ぐために僅かにスクロール開始をずらす
      setTimeout(() => dispatch(setActiveFacility(facilityId)), 100)
    },
    [dispatch]
  )

  useSearch(sortOrder, searchCondition, isResultDirty)

  useEffect(() => {
    // 戻るで表示した際にアクティブなタブを復元する為に履歴に保管
    history.replace({ ...history.location, state: { tabValue: activeTabValue, conditionExpanded } })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeTabValue, conditionExpanded])

  const onChangeConditionExpanded = useCallback((event: SyntheticEvent, expanded: boolean) => {
    setConditionExpanded(expanded)
  }, [])
  const onChangeTab = useCallback((value: string) => {
    setActiveTabValue(value)
  }, [])

  const onClickFacilityName = useCallback(
    (facilityId?: string) => {
      if (facilityId) {
        // この画面では施設カードに施設IDを設定しているのでnullはあり得ない
        dispatch(setActiveFacility(facilityId))
        nextHistory.push({
          pathname: facilityDetailUrl.url(facilityId),
          state: { availabilityBaseDate: searchCondition?.usageDate },
        })
      }
    },
    [dispatch, nextHistory, searchCondition]
  )

  // 遷移整合性チェック 年齢指定が必須である為条件なしはエラーとする
  const checkTransition = useCallback(() => !!searchCondition, [searchCondition])

  // 並び替え再建策時にMapがアンマウントされないように、検索結果ありかつ件数０時のみメッセージ表示
  const isNoSearchResult = allFacilities && allFacilities.length === 0

  return {
    conditionTags,
    isLimitOver,
    allFacilities,
    sortOrders,
    sortOrder,
    isDisabledCurrentLocation,
    mapCenter,
    isNoSearchResult,
    conditionExpanded,
    activeTabValue,
    goBack,
    onClickCurrentLocation,
    onClickFacilityPin,
    changeOrder,
    onChangeConditionExpanded,
    onChangeTab,
    onClickFacilityName,
    checkTransition,
  }
}

const useSearch = (sortOrder: string, searchCondition?: SearchCondition, isResultDirty?: boolean) => {
  const dispatch = useDispatch()
  const errorHandle = useErrorHandle()

  useEffect(() => {
    if (sortOrder === facilitySearchSortOrder.currentLocation && searchCondition?.currentLocation == null) {
      dispatch(
        showLoading(async () => {
          try {
            const position = await getCurrentPosition()
            dispatch(
              setFacilitySearchSortOrder({
                sortOrder: facilitySearchSortOrder.currentLocation,
                currentLocation: {
                  latitude: position.coords.latitude,
                  longitude: position.coords.longitude,
                },
              })
            )
          } catch {
            dispatch(
              setFacilitySearchSortOrder({
                sortOrder: facilitySearchSortOrder.facilityName,
                isDisabledCurrentLocation: true,
              })
            )
          }
        })
      )
    } else if (searchCondition && (isResultDirty == null || isResultDirty)) {
      dispatch(
        showLoading({
          process: errorHandle(async () => {
            const { isLimitOver, allFacilities } = await getFacilities(sortOrder, searchCondition)
            dispatch(setFacilitySearchResult({ searchResult: { isLimitOver, allFacilities } }))
          }),
          isHiddenMain: isResultDirty == null,
        })
      )
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchCondition, sortOrder, isResultDirty])
}

async function getFacilities(sortOrder: string, searchCondition: SearchCondition) {
  const input: GetFacilitiesInputDto = undefinedPropsToNull({
    latitude: searchCondition.currentLocation?.latitude,
    longitude: searchCondition.currentLocation?.longitude,
    areaIds: searchCondition.areas ?? [],
    ages: searchCondition.ages ?? [],
    freeWord: blankToNull(searchCondition.freeWord),
    reservationAcceptFlag: searchCondition.reservationAcceptFlag?.[0],
    projectIds: searchCondition.projectIds ?? [],
    facilityCategories: searchCondition.facilityCategories ?? [],
    searchFlagNos: toInts(searchCondition.otherDetailConditions) ?? [],
    usageDate: toApiYmd(searchCondition.usageDate),
    purposeOfUses: searchCondition.purposeOfUses ?? [],
    sortOrder,
  })
  const apiResponse = await executeGetFacilities(input)
  return {
    isLimitOver: apiResponse.resultCode === resultCodeGetFacilities.overLimit,
    allFacilities: apiResponse.result.map((facility) => {
      const { imageUrls, ...other } = facility
      return {
        ...nullPropsToUndefined(other),
        // 画像URLは穴あきで設定される可能性があるため
        imageUrl: imageUrls.find((url) => url),
      }
    }),
  }
}

function getConditionTags(codeMaster: CodeMaster, condition: SearchCondition) {
  const areaTags = toTags(codeMaster.areas, condition.areas)
  const ageTags = toTags(codeMaster.ages, condition.ages)
  const acceptWebReservationTag = toTags(codeMaster.reservationAcceptFlag, condition.reservationAcceptFlag)
  const projectTags = condition.isProjectSelected ? toTags(codeMaster.projects, condition.projectIds) : []
  const categoryTags = toTags(codeMaster.facilityCategories, condition.facilityCategories)
  const otherTags = toTags(codeMaster.otherDetailConditions, condition.otherDetailConditions)
  const purposeOfUseTags = toTags(codeMaster.purposeOfUses, condition.purposeOfUses)

  return [
    ...ageTags,
    ...areaTags,
    ...acceptWebReservationTag,
    formatYmd(condition.usageDate),
    ...projectTags,
    ...categoryTags,
    ...otherTags,
    ...purposeOfUseTags,
    condition.freeWord,
  ].filter((tag) => tag)
}

function toCodeMap(codeMaster?: CodeEntry[]) {
  return new Map(codeMaster?.map(({ value, label }) => [value, label]) ?? [])
}

function toTags(codeMaster?: CodeEntry[], values?: string[]) {
  const codeMap = toCodeMap(codeMaster)
  return values?.map((value) => codeMap.get(value) ?? value) ?? []
}
