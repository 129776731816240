import { executePostDecodeMst } from "../../dataAccess/webApi/dao/decodeMstDao";
import { PostDecodeMstDto } from "../../dataAccess/webApi/dto/decodeMstDto";

// StringObjectの型定義
type StringObject = {
  [key: string]: string;
};

export const getDecodeMstValue = async <T extends StringObject>(decodeMstInputList: T): Promise<{ [K in keyof T]: PostDecodeMstDto[] }> => {
  const promises = Object.entries(decodeMstInputList).map(([key, category]) => 
    executePostDecodeMst({ category })
      .then(response => [key, response.result] as const)
      .catch(error => {
        console.error(`デコードマスタ取得エラー： ${error}`);
        throw error;
      })
  );

  const results = await Promise.all(promises);

  return results.reduce((acc, [key, result]) => {
    acc[key as keyof T] = result;
    return acc;
  }, {} as { [K in keyof T]: PostDecodeMstDto[] });
};
