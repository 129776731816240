import { Analytics } from 'aws-amplify'
import { MouseEvent, useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { useHistory, useParams } from 'react-router'
import { showLoading } from '../../containers/common/store/slices/application'
import { GetFacilityDto } from '../../dataAccess/webApi/dto/facilitiesDto'
import { GetUseReasonDto } from '../../dataAccess/webApi/dto/useReasonDto'
import { ElapsedMillisecond, toApiYmd } from '../../utils/dateUtil'
import { NullPropsToUndefinedType } from '../../utils/typeUtil'
import { useErrorHandle } from '../common/error/errorHandler'
import { getFacility } from '../common/facility'
import { getUseReason } from '../common/useReason'
import { DecodeMstCategory } from '../common/constant/decodeMst'
import { getDecodeMstByCategories, GetDecodeMstByCategoriesReturn } from '../common/decodeMst'
import { executePostUsageFeeSetting } from '../../dataAccess/webApi/dao/usageFeeSettingDao'
import { PostUsageFeeSettingDto } from '../../dataAccess/webApi/dto/usageFeeSettingDto'
import { getLocationStatus, GetLocationStatusReturn } from '../common/locationStatus'

interface UrlParams {
  facilityId: string
}

interface LocationState {
  /** 空き状況基準日 */
  availabilityBaseDate?: ElapsedMillisecond
  documentFileUri?: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const { facilityId } = useParams<UrlParams>()
  const currentHistory = useHistory<LocationState | undefined>()
  const locationState = currentHistory.location.state

  const [facility, setFacility] = useState<NullPropsToUndefinedType<GetFacilityDto>>()
  const [useReason, setUseReason] = useState<NullPropsToUndefinedType<GetUseReasonDto>>()
  const [decodeMst, setDecodeMst] = useState<GetDecodeMstByCategoriesReturn>({})
  const [usageFeeSetting, setUsageFeeSetting] = useState<PostUsageFeeSettingDto | null>(null)
  const [locationStatus, setLocationStatus] = useState<GetLocationStatusReturn>([])
  const dispatch = useDispatch()

  const onClickLink = (
    event: MouseEvent<HTMLAnchorElement>,
    documentInfo: {
      documentFileUrl: string | null
      documentFileName: string | null
    }
  ) => {
    event.preventDefault()

    if (!documentInfo.documentFileUrl && documentInfo.documentFileName) {
      window.open(documentInfo.documentFileName,)
      return
    }

    if (!documentInfo.documentFileName && documentInfo.documentFileUrl) {
      const lastSegment = documentInfo.documentFileUrl.substring(
        documentInfo.documentFileUrl.lastIndexOf('/') + 1
      )
      fetch(documentInfo.documentFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = lastSegment
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => console.error('ダウンロードエラー:', error))
      return
    }

    if (documentInfo.documentFileUrl && documentInfo.documentFileName) {
      fetch(documentInfo.documentFileUrl)
      .then((response) => response.blob())
      .then((blob) => {
        const url = window.URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.style.display = 'none'
        a.href = url
        a.download = documentInfo.documentFileName ?? ''
        document.body.appendChild(a)
        a.click()
        window.URL.revokeObjectURL(url)
        document.body.removeChild(a)
      })
      .catch((error) => console.error('ダウンロードエラー:', error))
      return
    }
  }

  useEffect(() => {
    dispatch(
      showLoading(
        errorHandle(async () => {
          const [facilityDto, useReasonDto, resDecodeMst, resUsageFeeSetting, resLocationStatus] = await Promise.all([
            getFacility(facilityId),
            getUseReason(),
            getDecodeMstByCategories([
              DecodeMstCategory.AGE_REGION_BASED_FEE_TYPE,
              DecodeMstCategory.SCHEDULED_DAY_TYPE,
              DecodeMstCategory.AGE_REGION_BASED_FEE_DAY_TYPE,
              DecodeMstCategory.TIME_UNIT_TYPE,
            ]),
            executePostUsageFeeSetting({
              facilityId,
              targetDate: toApiYmd(new Date())
            }),
            getLocationStatus()
          ])

          setUsageFeeSetting(resUsageFeeSetting.result)
          setDecodeMst(resDecodeMst)
          setLocationStatus(resLocationStatus)

          setFacility(facilityDto)
          setUseReason(useReasonDto)
        })
      )
    )
    // AmazonPinpointへ追加情報送信。
    sendAnalyticsRecord(facilityId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    facilityId,
    facility,
    useReason,
    availabilityBaseDate: locationState?.availabilityBaseDate,
    decodeMst,
    usageFeeSetting,
    locationStatus,
    onClickLink,
  }
}

/**
 * AmazonPinpointへ追加情報送信
 * 呼び出し完了を待つ必要は無くエラーのハンドリング不要、awaitしないこと
 * @param facilityId 表示している施設ID
 */
const sendAnalyticsRecord = (facilityId: string) => {
  Analytics.record({
    name: 'facilityDetail',
    attributes: { facilityId },
  })
}
