import { useMemo, useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { getInterviewReservationSteps, getInterviewReservationSteps2 } from '../common/codeMaster'
import { useOperationLog } from '../common/operationLog'
import { getFacility } from '../common/facility'
import { useErrorHandle } from '../common/error/errorHandler'
import { OperationId } from '../common/constant/operationLog'
import { yesNo, interviewAcceptMethod } from '../common/constant/classification'
import { showLoading } from '../common/store/slices/application'
import {
  selectInterviewReserveEntry,
  clearInterviewReserve
} from '../common/store/slices/interviewReserve'

interface FacilityState {
  interviewDailyAcceptFlag: string
  interviewAcceptMethod: string
  interviewRequestCompletionInformation: string
  facilityName: string
  tel: string
  inquiryTime: string
}

export const useAction = () => {
  const errorHandle = useErrorHandle()
  const dispatch = useDispatch()
  const reserveEntry = useSelector(selectInterviewReserveEntry)
  const { addOperationLog } = useOperationLog()

  const [facility, setFacility] = useState<FacilityState>({
    interviewDailyAcceptFlag: yesNo.no,
    interviewAcceptMethod: interviewAcceptMethod.applicationOnly,
    interviewRequestCompletionInformation: '',
    facilityName: '',
    tel: '',
    inquiryTime: '',
  })

  const steps = useMemo(
    (facility?.interviewDailyAcceptFlag === yesNo.yes && facility?.interviewAcceptMethod === interviewAcceptMethod.interviewSlots)
      ? getInterviewReservationSteps2 : getInterviewReservationSteps,
    [facility]
  )

  useEffect(() => {
    addOperationLog({ operationId: OperationId.OP_00000001 })

    dispatch(
      showLoading(
        errorHandle(async () => {
          if (reserveEntry) {
            const facility = await getFacility(reserveEntry.facilityId)
            if (facility) {
              setFacility({
                interviewDailyAcceptFlag: facility.interviewDailyAcceptFlag ?? yesNo.no,
                interviewAcceptMethod: facility.interviewAcceptMethod ?? interviewAcceptMethod.applicationOnly,
                interviewRequestCompletionInformation: facility.interviewRequestCompletionInformation ?? '',
                facilityName: facility.facilityName ?? '',
                tel: facility.tel ?? '',
                inquiryTime: facility.inquiryTime ?? '',
              })
            }
          }
          dispatch(clearInterviewReserve())
        })
      )
    )
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  return {
    steps,
    facility,
  }
}
