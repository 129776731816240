import { Stack, styled } from '@mui/material'
import {
  getGenders,
  getInterviewReservationSteps,
  getInterviewReservationSteps2,
  getYesNos,
  getAvailabilityOfApplication,
} from '../../containers/common/codeMaster'
import {
  interviewReservationStep,
  interviewReservationStep2,
  yesNo,
  interviewAcceptMethod
} from '../../containers/common/constant/classification'
import { useAction, child } from '../../containers/interviewReservationForm/interviewReservationFormService'
import { translate } from '../../i18n'
import { AttentionLabel } from '../components/common/attentionLabel'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { BackButton } from '../components/common/buttons/backButton'
import { ButtonL } from '../components/common/buttons/buttonL'
import { ButtonOutlinedS } from '../components/common/buttons/buttonOutlinedS'
import { ElevatedCard } from '../components/common/elevatedCard'
import { FlexboxRowTop, Separator } from '../components/common/flexboxes'
import { GContainer, GItem, GItemContainer } from '../components/common/grids'
import { InfoCard } from '../components/common/infoCard'
import { DatePicker } from '../components/common/inputs/datePicker'
import { Form } from '../components/common/inputs/form'
import { RadioButtonGroup } from '../components/common/inputs/radioButtonGroup'
import { TextBox } from '../components/common/inputs/textBox'
import { StepBar } from '../components/common/stepBar'
import { ChildSelectDialog } from '../components/accountConfirmation/childSelectDialog'

const InformationAndNote = styled('div')({
  paddingLeft: '20px',
  margin: '0',
  whiteSpace: 'pre-wrap',
})

export const InterviewReservationForm = () => {
  const {
    formMethods,
    autoCompleteAddress,
    onSubmit,
    childBirthdayLimitDate,

    isOpenInterviewChildSelect,
    onCloseInterviewChildSelect,
    uninterviewedChilds,
    interviewedChildsAndStatus,
    isDisabledResidenceCategory,
    residenceCategories,
    isDisabledAddress1,
    watchAllFields,
    sysCtrl,
    facility
  } = useAction()
  
  return (
    <>
      <Stack spacing={2}>
        <ChildSelectDialog
          isOpen={isOpenInterviewChildSelect}
          onClose={onCloseInterviewChildSelect}
          childs={uninterviewedChilds}
          interviewedChildsAndStatus={interviewedChildsAndStatus}
          isShowToNextButton={!!uninterviewedChilds.length}
          isInterviewBulkReception={sysCtrl.interviewBulkReceptionFlag}
        />
      </Stack>
      <Form formMethods={formMethods} onSubmit={onSubmit}>
        <Stack spacing={2}>
          <GContainer>
            <GItem md={3}></GItem>
            <GItem xs={12} md={6}>
              <Stack spacing={2}>
                <div>
                  <StepBar
                    activeStepValue={
                      (facility?.interviewDailyAcceptFlag === yesNo.yes
                        && facility?.interviewAcceptMethod === interviewAcceptMethod.interviewSlots)
                      ? interviewReservationStep2.step2
                      : interviewReservationStep.step1
                    }
                    stepValueLabels={
                      (facility?.interviewDailyAcceptFlag === yesNo.yes
                        && facility?.interviewAcceptMethod === interviewAcceptMethod.interviewSlots)
                      ? getInterviewReservationSteps2()
                      : getInterviewReservationSteps()
                    }
                  />
                  <InfoCard>
                    <InformationAndNote>
                      {facility?.interviewRequestInformation}
                    </InformationAndNote>
                    {
                      (facility?.interviewDailyAcceptFlag === yesNo.yes
                        && facility?.interviewAcceptMethod === interviewAcceptMethod.applicationOnly)
                      && (
                        <InformationAndNote>
                          {facility?.interviewNote}
                        </InformationAndNote>
                      )
                    }
                  </InfoCard>
                </div>
                <div>
                  <GContainer rowSpacing={3}>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.parentName')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="parentName"
                          label={translate('interviewReservationForm.label.parentName')}
                          maxLength={100}
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.parentKana')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="parentKana"
                          label={translate('interviewReservationForm.label.parentKana')}
                          maxLength={100}
                          textType="katakana"
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.postalCode')}
                      </GItem>
                      <GItem xs={12}>
                        <FlexboxRowTop>
                          <TextBox
                            name="postalCode"
                            label={translate('interviewReservationForm.label.postalCode')}
                            textType="postalCode"
                            maxLength={8}
                            required
                          />
                          <Separator />
                          <ButtonOutlinedS onClick={autoCompleteAddress}>
                            {translate('interviewReservationForm.button.autoComplete')}
                          </ButtonOutlinedS>
                        </FlexboxRowTop>
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.prefecturesAndMunicipalities')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="address1"
                          label={translate('interviewReservationForm.label.prefecturesAndMunicipalities')}
                          maxLength={100}
                          required
                          disabled={isDisabledAddress1}
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.address')}
                      </GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="address2"
                          label={translate('interviewReservationForm.label.address')}
                          maxLength={100}
                          required
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>{translate('interviewReservationForm.label.buildingNameRoomNumber')}</GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="buildingNameRoomNumber"
                          label={translate('interviewReservationForm.label.buildingNameRoomNumber')}
                          maxLength={100}
                        />
                      </GItem>
                    </GItemContainer>
                    <GItemContainer xs={12}>
                      <GItem xs={12}>
                        <AttentionLabel>*</AttentionLabel>
                        {translate('interviewReservationForm.label.residenceCategory')}
                      </GItem>
                      <GItem xs={12}>
                        <RadioButtonGroup
                          name="residenceCategory"
                          label={translate('interviewReservationForm.label.residenceCategory')}
                          required
                          row
                          buttonValueLabels={residenceCategories}
                          disabled={isDisabledResidenceCategory}
                        />
                      </GItem>
                    </GItemContainer>
  
                    <GItemContainer xs={12}>
                      <GItem xs={12}>{translate('interviewReservationForm.label.relationship')}</GItem>
                      <GItem xs={12}>
                        <TextBox
                          name="relationship"
                          label={translate('interviewReservationForm.label.relationship')}
                          maxLength={50}
                        />
                      </GItem>
                    </GItemContainer>
                        {watchAllFields?.children?.map((child:child,index:number) => {
                          const isAvailabilityOfApplication = watchAllFields?.children?.[index]?.availabilityOfApplication === '1'
                          return (
                           <GItemContainer key={index} xs={12} rowSpacing={1}>
                              <GItem xs={12}>
                                  <ElevatedCard  title={translate('interviewReservationForm.title.child')}>
                                  <GContainer rowSpacing={1} >
                                    <GItemContainer xs={12}>
                                      <GItem xs={12}>
                                        <RadioButtonGroup
                                          name={`children.${index}.availabilityOfApplication`}
                                          label={translate('interviewReservationForm.label.childGender')}
                                          required
                                          row
                                          buttonValueLabels={getAvailabilityOfApplication()}
                                        />
                                      </GItem>
                                    </GItemContainer>
                                    <GItemContainer xs={12}>
                                      <GItem xs={12}>
                                        <AttentionLabel>*</AttentionLabel>
                                        {translate('interviewReservationForm.label.childName')}
                                      </GItem>
                                      <GItem xs={12}>
                                        <TextBox
                                          name={`children.${index}.childName`}
                                          label={translate('interviewReservationForm.label.childName')}
                                          maxLength={100}
                                          required
                                          disabled={!isAvailabilityOfApplication}
                                        />
                                      </GItem>
                                    </GItemContainer>
                                    <GItemContainer xs={12}>
                                      <GItem xs={12}>
                                        <AttentionLabel>*</AttentionLabel>
                                        {translate('interviewReservationForm.label.childKana')}
                                      </GItem>
                                      <GItem xs={12}>
                                        <TextBox
                                          name={`children.${index}.childKana`}
                                          label={translate('interviewReservationForm.label.childKana')}
                                          maxLength={100}
                                          textType="katakana"
                                          required
                                          disabled={!isAvailabilityOfApplication}
                                        />
                                      </GItem>
                                    </GItemContainer>
                                    <GItemContainer xs={12}>
                                      <GItem xs={12}>
                                        <AttentionLabel>*</AttentionLabel>
                                        {translate('interviewReservationForm.label.childBirthday')}
                                      </GItem>
                                      <GItem xs={12}>
                                        <DatePicker
                                          name={`children.${index}.childBirthday`}
                                          label={translate('interviewReservationForm.label.childBirthday')}
                                          maxDate={childBirthdayLimitDate}
                                          disabled={!isAvailabilityOfApplication||watchAllFields?.children?.[index]?.childInterviewPermitCount > 0}
                                          required
                                        />
                                      </GItem>
                                    </GItemContainer>
                                    
                                    {isAvailabilityOfApplication && (
                                      <>
                                        <GItemContainer xs={12}>
                                          <GItem xs={12}>
                                            <AttentionLabel>*</AttentionLabel>
                                            {translate('interviewReservationForm.label.childGender')}
                                          </GItem>
                                          <GItem xs={12}>
                                            <RadioButtonGroup
                                              name={`children.${index}.childGender`}
                                              label={translate('interviewReservationForm.label.childGender')}
                                              required
                                              row
                                              buttonValueLabels={getGenders()}
                                            />
                                          </GItem>
                                        </GItemContainer>
                                        <GItemContainer xs={12}>
                                          <GItem xs={12}>
                                            <AttentionLabel>*</AttentionLabel>
                                            {translate('interviewReservationForm.label.childAllergy')}
                                          </GItem>
                                          <GItem xs={12}>
                                            <RadioButtonGroup
                                              name={`children.${index}.childAllergyFlag`}
                                              label={translate('interviewReservationForm.label.childAllergyFlag')}
                                              required
                                              row
                                              buttonValueLabels={getYesNos().reverse()}
                                            />
                                          </GItem>
                                          <GItem xs={12}>
                                            <TextBox
                                              name={`children.${index}.childAllergy`}
                                              label={translate('interviewReservationForm.label.childAllergyContent')}
                                              maxLength={200}
                                              multiline
                                              minRows={4}
                                              fullWidth
                                            />
                                          </GItem>
                                        </GItemContainer>
                                        <GItemContainer xs={12}>
                                          <GItem xs={12}>
                                            <AttentionLabel>*</AttentionLabel>
                                            {translate('interviewReservationForm.label.childMedicalHistory')}
                                          </GItem>
                                          <GItem xs={12}>
                                            <RadioButtonGroup
                                              name={`children.${index}.childMedicalHistoryFlag`}
                                              label={translate('interviewReservationForm.label.childMedicalHistoryFlag')}
                                              required
                                              row
                                              buttonValueLabels={getYesNos().reverse()}
                                            />
                                          </GItem>
              
                                          <GItem xs={12}>
                                            <TextBox
                                              name={`children.${index}.childMedicalHistory`}
                                              label={translate('interviewReservationForm.label.childMedicalHistoryContent')}
                                              maxLength={200}
                                              multiline
                                              minRows={4}
                                              fullWidth
                                            />
                                          </GItem>
                                        </GItemContainer>
                                        {/*TODO：HOIKU-178にて暫定的に母子健康手帳の項目を非表示＆登録されないようにする
                                        <GItemContainer xs={12}>
                                          <GItem xs={12}>
                                            <AttentionLabel>*</AttentionLabel>
                                            {translate('interviewReservationForm.label.maternalHandbookNo')}
                                          </GItem>
                                          <GItem xs={12}>
                                            <TextBox
                                              name={`children.${index}.maternalHandbookNo`}
                                              label={translate('interviewReservationForm.label.maternalHandbookNo')}
                                              maxLength={20}
                                              required
                                            />
                                          </GItem>
                                        </GItemContainer>
                                        */}
                                        <GItemContainer xs={12}>
                                          <GItem xs={12}>{translate('interviewReservationForm.label.note')}</GItem>
                                          <GItem xs={12}>
                                            <TextBox
                                              name={`children.${index}.note`}
                                              label={translate('interviewReservationForm.label.note')}
                                              maxLength={1000}
                                              multiline
                                              minRows={4}
                                              fullWidth
                                            />
                                          </GItem>
                                        </GItemContainer>
                                        <GItemContainer xs={12}>
                                          <GItem xs={12}>
                                            <div>{translate('interviewReservationForm.label.facilityNumber')}</div>
                                            <div>{translate('interviewReservationForm.label.facilityNumberSupplement')}</div>
                                          </GItem>
                                          <GItem xs={12}>
                                            <TextBox
                                              name={`children.${index}.facilityNumber`}
                                              label={translate('interviewReservationForm.label.facilityNumber')}
                                              maxLength={12}
                                              fullWidth
                                            />
                                          </GItem>
                                        </GItemContainer>
                                      </>
                                    )}
                                    
                                  </GContainer>
                                </ElevatedCard>
                              </GItem>
                            </GItemContainer>
                          )
                        })}
                  </GContainer>
                </div>
              </Stack>
            </GItem>
            <GItem md={3}></GItem>
          </GContainer>
          <BottomButtonGroup>
            <ButtonL fullWidth type="submit">
              {translate('interviewReservationForm.button.confirmation')}
            </ButtonL>
            <BackButton />
          </BottomButtonGroup>
        </Stack>
      </Form>
    </>
  )
}
