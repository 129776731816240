import { Stack, styled } from '@mui/material'
import React from 'react'
import { tabValue, useAction } from '../../containers/facilitySearchResult/facilitySearchResultService'
import { getClassificationValueLabels, translate } from '../../i18n'
import { fromNumber } from '../../utils/stringUtil'
import { AccordionCard } from '../components/common/accordionCard'
import { BottomButtonGroup } from '../components/common/bottomButtonGroup'
import { ButtonOutlinedResponsive } from '../components/common/buttons/buttonOutlinedResponsive'
import { ButtonS } from '../components/common/buttons/buttonS'
import { ScrollTopFab } from '../components/common/buttons/scrollTopFab'
import { ErrorMessageCard } from '../components/common/errorMessageCard'
import { FacilityMap } from '../components/common/facilityMap'
import { BottomGItem, CenterMiddleGItem, GContainer, GItem, RightGItem } from '../components/common/grids'
import { SelectNoBind } from '../components/common/inputs/selectNoBind'
import { LazyMount } from '../components/common/lazyMount'
import { SearchConditionTag } from '../components/common/searchConditionTag'
import { TabBar } from '../components/common/tabBar'
import { TitleLabel } from '../components/common/titleLabel'
import { TransitionCheck } from '../components/common/transitionCheck'
import { ResultBox } from '../components/facilitySearchResult/resultBox'

const ConditionTagGItem = styled(GItem)({
  '& > *': {
    margin: '1px',
  },
})
const mapMinHeight = 200
const MapGItem = styled(GItem, { shouldForwardProp: (prop) => prop !== 'isShown' })<{
  isShown?: boolean
}>(({ theme, isShown }) => ({
  ...(isShown && {
    minHeight: mapMinHeight,
    maxHeight: 400,
  }),
  [theme.breakpoints.up('md')]: {
    position: 'sticky',
    // アプリヘッダー分
    top: theme.mixins.toolbar.minHeight,
  },
}))

const OrderGItem = styled(RightGItem)({
  alignItems: 'center',
})

export const FacilitySearchResult = () => {
  const {
    conditionTags,
    isLimitOver,
    allFacilities,
    sortOrders,
    sortOrder,
    isDisabledCurrentLocation,
    mapCenter,
    isNoSearchResult,
    conditionExpanded,
    activeTabValue,
    goBack,
    onChangeConditionExpanded,
    onClickCurrentLocation,
    onClickFacilityPin,
    changeOrder,
    onChangeTab,
    onClickFacilityName,
    checkTransition,
  } = useAction()
  if (allFacilities == null) {
    return <TransitionCheck check={checkTransition} />
  }
  const isMapShown = activeTabValue === tabValue.mapShow
  return (
    <>
      <ScrollTopFab />
      <Stack spacing={2}>
        <TransitionCheck check={checkTransition} />
        <div>
          <GContainer spacing={1}>
            {isLimitOver && allFacilities && (
              <GItem xs={12}>
                <ErrorMessageCard
                  messages={[translate('facilitySearchResult.error.limitOver', fromNumber(allFacilities.length + 1))]}
                />
              </GItem>
            )}
            <GItem xs={12}>
              <AccordionCard
                title={translate('facilitySearchResult.title.searchCondition')}
                expanded={conditionExpanded}
                onChange={onChangeConditionExpanded}
              >
                <GContainer rowSpacing={1}>
                  <ConditionTagGItem xs={12} md={10}>
                    {conditionTags.map((tag, index) => (
                      <SearchConditionTag key={index}>{tag}</SearchConditionTag>
                    ))}
                  </ConditionTagGItem>
                  <CenterMiddleGItem xs={12} md={2}>
                    <ButtonS onClick={goBack}>
                      {translate('facilitySearchResult.button.selectSearchConditionAgain')}
                    </ButtonS>
                  </CenterMiddleGItem>
                </GContainer>
              </AccordionCard>
            </GItem>
            <GItem xs={12}>
              <TabBar
                value={activeTabValue}
                valueLabels={getClassificationValueLabels(
                  'facilitySearchResult.classification.tabValue',
                  Object.values(tabValue)
                )}
                onChange={onChangeTab}
              />
            </GItem>
            {isNoSearchResult ? (
              <GItem xs={12}>
                <ErrorMessageCard messages={[translate('facilitySearchResult.error.notFound')]} />
              </GItem>
            ) : (
              <>
                <MapGItem
                  xs={12}
                  {...(isMapShown
                    ? {
                        md: true,
                        isShown: isMapShown,
                      }
                    : {
                        md: 2,
                      })}
                >
                  <LazyMount isShown={isMapShown}>
                    <FacilityMap
                      center={mapCenter}
                      onClickCurrentLocation={onClickCurrentLocation}
                      isDisabledCurrentLocation={isDisabledCurrentLocation}
                      facilities={allFacilities}
                      onClickFacilityPin={onClickFacilityPin}
                    />
                  </LazyMount>
                </MapGItem>
                <GItem xs={12} md={8}>
                  <GContainer rowSpacing={2}>
                    <BottomGItem xs="auto">
                      <TitleLabel>
                        {translate('facilitySearchResult.title.numberOfResult', fromNumber(allFacilities.length))}
                      </TitleLabel>
                    </BottomGItem>
                    <OrderGItem xs>
                      {translate('system.label.sort')}：
                      <SelectNoBind valueLabels={sortOrders} onChange={changeOrder} value={sortOrder} />
                    </OrderGItem>
                    <GItem xs={12}>
                      <ResultBox
                        isShownPin={isMapShown}
                        facilities={allFacilities}
                        onClickFacilityName={onClickFacilityName}
                        mapMinHeight={mapMinHeight}
                      />
                    </GItem>
                  </GContainer>
                </GItem>
              </>
            )}
          </GContainer>
        </div>
        <BottomButtonGroup>
          <ButtonOutlinedResponsive onClick={goBack}>
            {translate('system.button.back')}
          </ButtonOutlinedResponsive>
        </BottomButtonGroup>
      </Stack>
    </>
  )
}
