/**
 * メッセージ取得関数（置換あり）
 * @param messageStr メッセージ文字列
 * @param args メッセージ文字列の{n}部分
 */
 export const GetFormatMessage = (messageStr: string, args: string[]):string => {
   for (let i = 0; i < args.length; i++) {
    messageStr = messageStr.replace("{" + i + "}", args[i]);
  }
  return messageStr;
};
